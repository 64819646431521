import React from 'react';
import Layout from '../../components/Layout/layout';
import AnnualLeaveCalculator from '../../Views/Portal/Tools/Calculators/Annuel-Leave-Calculator/Annuel-Leave-Calculator';

const AnnualLeaveCalculatorPage: React.FC = () => {
  return (
    <Layout>
      <AnnualLeaveCalculator />
    </Layout>
  );
};

export default AnnualLeaveCalculatorPage;
